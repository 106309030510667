@value vars: 'styles/vars.module.css';
@value phone, tablet-max, phone-medium-min from vars;

/* banner section */

.bannerTextWrapper {
  margin-top: 21.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 44px;
}

@media (max-width: phone) {
  .bannerTextWrapper {
    margin-top: 16.4px;
  }
}

.bannerParagraph {
  color: var(--color-text-secondary);
  font-size: 16px;

  font-weight: 400;
}

/* background section */
.landingBackground {
  position: relative;
  align-self: flex-end;
}

.landingBackground:after {
  content: '';
  background-image: url('/public/assets/landing/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5; /* Here is your opacity */
}

/* cards section */
.cards {
  max-width: 1440px;
  padding-top: 64px;
  margin-bottom: 84px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

@media (max-width: phone) {
  .cards {
    padding-top: 44.5px;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 100%;
    row-gap: 12px;
    column-gap: 0px;
    margin-bottom: 138px;
  }

  .landingBackground::after {
    background-image: url('/public/assets/landing/background-mobile.svg');
    background-position: bottom;
  }
}
@media (max-width: phone-medium-min) {
  .landingBackground {
    background-size: initial;
  }
}
