.footerContainer {
  border-top: 1px solid rgba(244, 244, 244, 0.5);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -43.75%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.footer {
  display: flex;
  align-items: center;
  padding: 16px 0;
  height: 56px;
}

.footerText {
  color: var(--color-text-secondary);
  font-size: 14px;
  font-weight: 400;
}

.footerIcon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
