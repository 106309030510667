@value vars: 'styles/vars.module.css';
@value phone from vars;

.cardContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.cardHeader {
  display: flex;
  align-items: center;
}

.cardHeaderText {
  color: var(--color-text-secondary);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cardDescription {
  color: var(--color-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
}

/* icons */
.linkIcon {
  align-self: start;
  width: 17px;
  height: 17px;
  fill: var(--color-text-tertiary);
  opacity: 0.5;
  margin-left: auto;
}

.headerIcon {
  width: 32px;
  height: 32px;

  margin-right: 10px;
}

@media (max-width: phone) {
  .cardHeaderText {
    font-size: 16px;
  }

  .cardDescription {
    font-size: 14px;
  }
}
