@value vars: 'styles/vars.module.css';
@value phone, tablet-max from vars;

.header {
  padding: 34px 0;
  display: flex;
  align-items: center;
}

.logo {
  width: 37px;
  height: 38px;
  margin-right: 16px;
}

.logoText {
  width: 120px;
  height: 38px;
}

@media (max-width: phone) {
  .header {
    padding: 24px 0;
  }

  .logo {
    width: 21px;
    height: 21px;
    margin-right: 8px;
  }

  .logoText {
    width: 67px;
    height: 27px;
  }
}
