.card {
  text-decoration: none;
  background-color: var(--color-card-bg);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-card-border);
  cursor: pointer;
  transition: border 50ms ease-in-out;
}

.card svg {
  transition: fill 50ms ease-in-out;
}

/* hover effects */
.card:hover {
  border: 1px solid var(--color-card-border-hover);
}

.card:hover svg {
  fill: var(--color-hover);
}
